[
  {
    "names": [
      "tailwind.css"
    ],
    "generatedName": "tailwind.9cd09a34e959c52abefe8189b8620c7a.css"
  },
  {
    "names": [
      "pl-home--__index.map"
    ],
    "generatedName": "-__index.fe4914a1e3dcbbad6e537754b446e867.map.json"
  },
  {
    "names": [
      "pl-home--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.761a98e0ded30bc7603c801742067fc2.js"
  },
  {
    "names": [
      "pl-home-posts.map"
    ],
    "generatedName": "posts.9e53842aa07625d7ad4495b4cdc5238c.map.json"
  },
  {
    "names": [
      "pl-home-posts-bundle.js"
    ],
    "generatedName": "posts-bundle.25cdd6d8203b365cf8d0c4ae3cf0a48e.js"
  },
  {
    "names": [
      "pl-home-anti-spam-policy.map"
    ],
    "generatedName": "anti-spam-policy.e6746f62ccd127caaef86153ae39960b.map.json"
  },
  {
    "names": [
      "pl-home-anti-spam-policy-bundle.js"
    ],
    "generatedName": "anti-spam-policy-bundle.9fb0ebca604952978fc9f8296bd86930.js"
  },
  {
    "names": [
      "pl-home-privacy-policy.map"
    ],
    "generatedName": "privacy-policy.b9ff9ce155686e2ca6487af22c818b41.map.json"
  },
  {
    "names": [
      "pl-home-privacy-policy-bundle.js"
    ],
    "generatedName": "privacy-policy-bundle.5475da0ac5b93179e5bae3c12540baf1.js"
  },
  {
    "names": [
      "pl-home-robots_txt.map"
    ],
    "generatedName": "robots_txt.af684ecf134984ef6e6eb78abd871057.map.json"
  },
  {
    "names": [
      "pl-home-robots_txt-bundle.js"
    ],
    "generatedName": "robots_txt-bundle.b8a23e1a1ab601b810341c9a9b9a2d89.js"
  },
  {
    "names": [
      "pl-home-sitemap_xml.map"
    ],
    "generatedName": "sitemap_xml.b6574f8efb6a7df70031eee8950a60da.map.json"
  },
  {
    "names": [
      "pl-home-sitemap_xml-bundle.js"
    ],
    "generatedName": "sitemap_xml-bundle.42fa343e47737b27e63eee58858ea7ae.js"
  }
]